import {
  AddLinesInput,
  ShopifyPrice,
  ShopifyProductVariant,
} from '@magal/models'
import { styled } from '@magal/styles'
import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AttentiveAddToCart,
  GTMAddToCart,
  TripleAtc,
} from '@magal/services/gtm-service'
import { Button, Loader, PricePrimitive } from '@magal/components'
import { useCartContext } from '../useCart'
import { v4 as uuidv4 } from 'uuid'
import {
  BUNDLE_ID_KEY,
  BUNDLE_ID_PREFIX_ADDITIONAL,
  BUNDLE_ID_PREFIX_MAIN,
} from '@magal/services/shopify-service'
import toast from 'react-hot-toast'
import { DoneIcon } from '@magal/icons'
import { DiscountPercentage, formatDiscount } from '@magal/utils'
import { useProductsSettings } from '@magal/product'

const ButtonWrapper = styled(Button, {
  variants: {
    typeIcon: {
      true: {
        color: '$green_09',
        textIndent: '-999px',
        width: '$48',
        height: '$48',
        borderRadius: '$rMax',
        background: 'transparent !important',
        placeContent: 'center',
        overflow: 'hidden',
      },
      false: {
        color: '$white',
        whiteSpace: 'nowrap',
        fontSize: '16px !important',
        fontWeight: '700 !important',
      },
    },
  },
})

type AddToCartButtonProps = {
  mainVariant?: ShopifyProductVariant
  additionalVariants: ShopifyProductVariant[]
  attributes: { key: string; value: string }[]
  readyToAdd?: boolean
  currentPrice?: ShopifyPrice
  onAddedToCart?: () => void
  disabled?: boolean
  onError: (error: boolean) => void
  typeIcon?: boolean
  isPortal?: boolean
  showUpsells: (off: boolean) => void
  fakeCart?: boolean
  fakeCartClick?: () => void
}

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  mainVariant,
  additionalVariants,
  attributes,
  readyToAdd,
  currentPrice,
  onAddedToCart,
  disabled,
  onError,
  typeIcon = false,
  isPortal = false,
  showUpsells,
  fakeCart = false,
  fakeCartClick = () => null,
}) => {
  const { addCartLines, openMiniCart } = useCartContext()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation('sectionProductConversionArea')
  const [discount, setDiscount] = useState<DiscountPercentage>()
  const { upsells } = useProductsSettings()

  useEffect(() => {
    setTimeout(() => {
      if (window.discountPercentage.enableDiscount) {
        setDiscount(window.discountPercentage)
      }
    }, 100)
  }, [])

  const getLines = (
    mainVariant: ShopifyProductVariant,
    additionalVariants: ShopifyProductVariant[],
  ) => {
    const uuid = uuidv4()

    return [
      {
        merchandiseId: mainVariant.id,
        quantity: 1,
        attributes: additionalVariants.length
          ? [
              {
                key: BUNDLE_ID_KEY,
                value: `${BUNDLE_ID_PREFIX_MAIN}${uuid}`,
              },
              ...attributes,
            ]
          : attributes,
      },
      ...additionalVariants.map((v) => ({
        merchandiseId: v.id,
        quantity: 1,
        attributes: [
          {
            key: BUNDLE_ID_KEY,
            value: `${BUNDLE_ID_PREFIX_ADDITIONAL}${uuid}`,
          },
        ],
      })),
    ]
  }

  const handleAddToCartClick = async () => {
    if (fakeCart && mainVariant) {
      const cartLine = {
        merchandise: mainVariant,
        quantity: 1,
        id: `${mainVariant?.id}`,
      }

      GTMAddToCart(cartLine, 1)
      fakeCartClick()

      return
    }

    if (!readyToAdd) {
      onError(!readyToAdd)
      toast(<>Please complete all&nbsp;the&nbsp;personalization steps</>)
      const reviewsContainer = document.querySelector('#productCart')

      if (reviewsContainer) {
        window.scrollBy({
          left: 0,
          top: reviewsContainer.getBoundingClientRect().top,
          behavior: 'smooth',
        })
      }
      return
    }
    if (
      !mainVariant ||
      (mainVariant && !mainVariant.availableForSale) ||
      additionalVariants.some((v) => !v.availableForSale)
    ) {
      toast('We are sorry, variant with selected options is not available')
      return
    }
    if (mainVariant) {
      setIsLoading(true)

      const linesToAdd: AddLinesInput = getLines(
        mainVariant,
        additionalVariants,
      )

      const addedLines = await addCartLines(linesToAdd)

      if (addedLines) {
        window.dispatchEvent(
          new CustomEvent('product::added', {
            detail: linesToAdd,
          }),
        )

        if (upsells) {
          showUpsells(true)
        }
        if (onAddedToCart) {
          onAddedToCart()
        }
        GTMAddToCart(addedLines[0], 1)
        AttentiveAddToCart({
          productId: mainVariant.id,
          productVariantId: mainVariant.sku,
          name: mainVariant.product.title,
          value: mainVariant.priceV2.amount,
          currency: mainVariant.priceV2.currencyCode,
        })
        TripleAtc({ item: mainVariant.sku, q: 1 })
        setTimeout(() => openMiniCart(t('addedToCart')), 200)
      }
      setIsLoading(false)
    }
  }

  const optionalRendeDoneIcon =
    mainVariant && mainVariant.availableForSale && typeIcon

  return (
    <ButtonWrapper
      typeIcon={typeIcon}
      appearance={optionalRendeDoneIcon ? 'roundOutline' : 'solidRed'}
      onClick={handleAddToCartClick}
      disabled={disabled}
    >
      {optionalRendeDoneIcon && <DoneIcon />}
      {disabled ? (
        t('outOfStock')
      ) : isLoading ? (
        <Loader size={'small'} />
      ) : (
        <div>
          {isPortal && !readyToAdd ? (
            t('personalize')
          ) : (
            <>
              {t('addToCart')}
              {currentPrice && (
                <>
                  {' '}
                  —&nbsp;
                  {fakeCart ? (
                    t('customize')
                  ) : (
                    <PricePrimitive
                      price={formatDiscount(currentPrice, discount)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </ButtonWrapper>
  )
}
