import { createContext, FC, useContext, useState } from 'react'
import { getShopifyProductColorsMap2 } from '@magal/services/shopify-service'
import { useRouter } from 'next/router'
import {
  ShopifyProductColor,
  ShopifyProductColorsMap,
  SiteConfiguration,
} from '@magal/models'

type ProductsSettingsContextType = {
  getProductColors: (title: string) => ShopifyProductColor[]
  updateProductColorsMap: (titles: string[]) => void
  colorsConfig?: SiteConfiguration['product']['colorsConfig']
  colorOrder?: SiteConfiguration['product']['colorOrder']
  productCardBackgroundConfig?: SiteConfiguration['product']['backgroundColor']
  tagsConfig: SiteConfiguration['product']['tags']
  stringColorsConfig: SiteConfiguration['product']['stringColors']
  upsells?: SiteConfiguration['product']['upsells']
  shopifyTags?: (string | undefined)[] | undefined
  labels?: (string | undefined)[] | undefined
}

const ProductsSettingsContext = createContext<ProductsSettingsContextType>({
  getProductColors: () => [],
  updateProductColorsMap: () => null,
  colorsConfig: undefined,
  colorOrder: [],
  productCardBackgroundConfig: undefined,
  tagsConfig: undefined,
  stringColorsConfig: undefined,
  upsells: {},
})

export const ProductsSettingsProvider: FC<{
  colorsConfig?: SiteConfiguration['product']['colorsConfig']
  colorOrder?: SiteConfiguration['product']['colorOrder']
  productCardBackgroundConfig?: SiteConfiguration['product']['backgroundColor']
  tagsConfig?: SiteConfiguration['product']['tags']
  stringColorsConfig: SiteConfiguration['product']['stringColors']
  upsells: SiteConfiguration['product']['upsells']
}> = ({
  children,
  colorsConfig,
  colorOrder,
  productCardBackgroundConfig,
  tagsConfig,
  stringColorsConfig,
  upsells,
}) => {
  const { locale } = useRouter()
  const [productColorsMap, setProductColorsMap] =
    useState<ShopifyProductColorsMap>({})

  const getProductColors: ProductsSettingsContextType['getProductColors'] = (
    title,
  ) => {
    return productColorsMap[title] ?? null
  }
  const updateProductColorsMap: ProductsSettingsContextType['updateProductColorsMap'] =
    async (titles) => {
      const titlesToFetch = titles.reduce((acc: string[], val) => {
        return !productColorsMap[val] ? [...acc, val] : [...acc]
      }, [])
      const res = await getShopifyProductColorsMap2(titlesToFetch, locale)

      if (!res) return
      if (colorsConfig) setProductColorsMap({ ...productColorsMap, ...res })
    }

  return (
    <ProductsSettingsContext.Provider
      value={{
        getProductColors,
        updateProductColorsMap,
        colorOrder,
        colorsConfig,
        productCardBackgroundConfig,
        tagsConfig,
        stringColorsConfig,
        upsells,
      }}
    >
      {children}
    </ProductsSettingsContext.Provider>
  )
}

export const useProductsSettings = (): ProductsSettingsContextType =>
  useContext(ProductsSettingsContext)
