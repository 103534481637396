import { FC } from 'react'

export const LogoLoading: FC = () => (
  <svg viewBox="0 0 48 30" fill="#565C54" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.4583 8.23259C46.8195 8.23259 47.8404 7.19811 47.8404 5.8619C47.8404 4.52569 46.8195 3.49121 45.4583 3.49121C44.0971 3.49121 43.0762 4.52569 43.0762 5.8619C43.0762 7.19811 44.0971 8.23259 45.4583 8.23259Z" />
    <path d="M45.4583 21.9395C44.0971 21.9395 43.0762 22.9308 43.0762 24.267C43.0762 25.6032 44.0545 26.6377 45.4583 26.6377C46.862 26.6377 47.8404 25.6463 47.8404 24.267C47.8404 22.8877 46.862 21.9395 45.4583 21.9395Z" />
    <path d="M30.7976 1L16.8026 17.9828L2.89259 1H0V29.5345H3.31797V6.90517L16.207 22.5948H17.3555L30.2446 6.90517V29.5345H33.6051V1H30.7976Z" />
    <animate
      id="animation1"
      attributeName="opacity"
      from="0"
      to="1"
      dur=".5s"
      begin="0s;animation2.end"
    />
    <animate
      id="animation2"
      attributeName="opacity"
      from="1"
      to="0"
      dur=".5s"
      begin="animation1.end"
    />
  </svg>
)
